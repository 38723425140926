@font-face {
  font-family: 'byside';
  src:
    url('fonts/byside.woff2?rck9u6') format('woff2'),
    url('fonts/byside.ttf?rck9u6') format('truetype'),
    url('fonts/byside.woff?rck9u6') format('woff'),
    url('fonts/byside.svg?rck9u6#byside') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="byside-icon-"], [class*=" byside-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'byside' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.byside-icon-livechat-out:before {
  content: "\eaad";
}
.byside-icon-livechat-web:before {
  content: "\eaaf";
}
.byside-icon-livechat-whatsapp:before {
  content: "\eab1";
}
.byside-icon-video-broadcast:before {
  content: "\eab3";
}
.byside-icon-video-call:before {
  content: "\eab5";
}
.byside-icon-voice-inbound-new:before {
  content: "\eab7";
}
.byside-icon-voice-outbound-new:before {
  content: "\eab9";
}
.byside-icon-click2call-new:before {
  content: "\eabc";
}
.byside-icon-chatbot-web:before {
  content: "\eabe";
}
.byside-icon-chatbot-out:before {
  content: "\eac0";
}
.byside-icon-chatbot-whatsapp:before {
  content: "\eac2";
}
.byside-icon-audios-app:before {
  content: "\eaac";
}
.byside-icon-ai:before {
  content: "\eaab";
}
.byside-icon-channels:before {
  content: "\eaaa";
}
.byside-icon-custom-integration:before {
  content: "\eaa9";
}
.byside-icon-file-audio:before {
  content: "\eaa7";
}
.byside-icon-file-video:before {
  content: "\eaa8";
}
.byside-icon-audio-report:before {
  content: "\eaa1";
}
.byside-icon-download-small:before {
  content: "\eaa2";
}
.byside-icon-export-customize:before {
  content: "\eaa3";
}
.byside-icon-open-in-window:before {
  content: "\eaa4";
}
.byside-icon-report-icon:before {
  content: "\eaa5";
}
.byside-icon-transcription-icon:before {
  content: "\eaa6";
}
.byside-icon-attended-transfer:before {
  content: "\ea9c";
}
.byside-icon-blind-transfer:before {
  content: "\ea9d";
}
.byside-icon-transfer-number:before {
  content: "\ea9e";
}
.byside-icon-transfer-skill:before {
  content: "\ea9f";
}
.byside-icon-transfer-view:before {
  content: "\eaa0";
}
.byside-icon-leads-app:before {
  content: "\ea99";
}
.byside-icon-campaigns-app:before {
  content: "\ea9a";
}
.byside-icon-exports-app:before {
  content: "\ea9b";
}
.byside-icon-order-down:before {
  content: "\ea96";
}
.byside-icon-order-up:before {
  content: "\ea97";
}
.byside-icon-export-rule:before {
  content: "\ea98";
}
.byside-icon-section-adcenter:before {
  content: "\ea63";
}
.byside-icon-alerts:before {
  content: "\ea64";
}
.byside-icon-app-click2call:before {
  content: "\ea65";
}
.byside-icon-app-chat:before {
  content: "\ea66";
}
.byside-icon-app-notebook:before {
  content: "\ea67";
}
.byside-icon-app-pbx:before {
  content: "\ea68";
}
.byside-icon-app-user-management:before {
  content: "\ea69";
}
.byside-icon-app-web2call:before {
  content: "\ea6a";
}
.byside-icon-arrow-down:before {
  content: "\ea6b";
}
.byside-icon-arrow-left:before {
  content: "\ea6c";
}
.byside-icon-arrow-right:before {
  content: "\ea6d";
}
.byside-icon-arrow-up:before {
  content: "\ea6e";
}
.byside-icon-birthday:before {
  content: "\ea6f";
}
.byside-icon-browser:before {
  content: "\ea70";
}
.byside-icon-circle-minimize:before {
  content: "\ea71";
}
.byside-icon-call-contact:before {
  content: "\ea72";
}
.byside-icon-chat-app:before {
  content: "\ea73";
}
.byside-icon-chat-contact:before {
  content: "\ea74";
}
.byside-icon-circle-close:before {
  content: "\ea75";
}
.byside-icon-city:before {
  content: "\ea76";
}
.byside-icon-conference:before {
  content: "\ea77";
}
.byside-icon-section-console:before {
  content: "\ea78";
}
.byside-icon-country:before {
  content: "\ea79";
}
.byside-icon-dashboard-sales:before {
  content: "\ea7a";
}
.byside-icon-section-dashboard:before {
  content: "\ea7b";
}
.byside-icon-export:before {
  content: "\ea7c";
}
.byside-icon-first-name:before {
  content: "\ea7d";
}
.byside-icon-gender:before {
  content: "\ea7e";
}
.byside-icon-app-calculator:before {
  content: "\ea7f";
}
.byside-icon-last-contact-date:before {
  content: "\ea80";
}
.byside-icon-last-name:before {
  content: "\ea81";
}
.byside-icon-section-leads:before {
  content: "\ea82";
}
.byside-icon-mood:before {
  content: "\ea83";
}
.byside-icon-no-icon:before {
  content: "\ea84";
}
.byside-icon-note:before {
  content: "\ea85";
}
.byside-icon-nps:before {
  content: "\ea86";
}
.byside-icon-open-details:before {
  content: "\ea87";
}
.byside-icon-pbx:before {
  content: "\ea88";
}
.byside-icon-app-performance:before {
  content: "\ea89";
}
.byside-icon-plus:before {
  content: "\ea8a";
}
.byside-icon-report-download:before {
  content: "\ea8b";
}
.byside-icon-app-reports:before {
  content: "\ea8c";
}
.byside-icon-settings:before {
  content: "\ea8d";
}
.byside-icon-state:before {
  content: "\ea8e";
}
.byside-icon-street:before {
  content: "\ea8f";
}
.byside-icon-subscriptions:before {
  content: "\ea90";
}
.byside-icon-section-support:before {
  content: "\ea91";
}
.byside-icon-tags:before {
  content: "\ea92";
}
.byside-icon-ticket-number:before {
  content: "\ea93";
}
.byside-icon-wrapup-code:before {
  content: "\ea94";
}
.byside-icon-zip-code:before {
  content: "\ea95";
}
.byside-icon-integration-icon:before {
  content: "\ea62";
}
.byside-icon-public-dashboard:before {
  content: "\ea61";
}
.byside-icon-coloured-rec:before {
  content: "\ea5d";
  color: #f95d50;
}
.byside-icon-rec-pause-coloured .path1:before {
  content: "\ea5e";
  color: rgb(249, 93, 80);
}
.byside-icon-rec-pause-coloured .path2:before {
  content: "\ea5f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.byside-icon-rec-pause-coloured .path3:before {
  content: "\ea60";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.byside-icon-mute2:before {
  content: "\e974";
}
.byside-icon-pause1:before {
  content: "\ea58";
}
.byside-icon-rec-pause:before {
  content: "\ea59";
}
.byside-icon-rec-resume:before {
  content: "\ea5a";
}
.byside-icon-rec:before {
  content: "\ea5b";
}
.byside-icon-transfer:before {
  content: "\ea5c";
}
.byside-icon-cdp:before {
  content: "\ea57";
}
.byside-icon-shared:before {
  content: "\ea55";
}
.byside-icon-folders:before {
  content: "\ea56";
}
.byside-icon-open-url:before {
  content: "\ea54";
}
.byside-icon-email-ticketing:before {
  content: "\e983";
}
.byside-icon-ecommerce-products-list:before {
  content: "\ea52";
}
.byside-icon-reorder:before {
  content: "\ea51";
}
.byside-icon-gamification:before {
  content: "\ea48";
}
.byside-icon-trophy-1:before {
  content: "\ea49";
}
.byside-icon-trophy-2:before {
  content: "\ea4a";
}
.byside-icon-trophy-3:before {
  content: "\ea4b";
}
.byside-icon-trophy-4:before {
  content: "\ea4c";
}
.byside-icon-trophy-5:before {
  content: "\ea4d";
}
.byside-icon-trophy-6:before {
  content: "\ea4e";
}
.byside-icon-trophy-7:before {
  content: "\ea4f";
}
.byside-icon-trophy-8:before {
  content: "\ea50";
}
.byside-icon-email-small .path1:before {
  content: "\ea46";
  color: rgb(255, 255, 255);
}
.byside-icon-email-small .path2:before {
  content: "\ea47";
  margin-left: -1.2080078125em;
  color: rgb(179, 179, 179);
}
.byside-icon-live-pages:before {
  content: "\ea44";
}
.byside-icon-live-visitors:before {
  content: "\ea45";
}
.byside-icon-due-date:before {
  content: "\ea43";
}
.byside-icon-line-graph-2:before {
  content: "\ea41";
}
.byside-icon-line-area-graph:before {
  content: "\ea42";
}
.byside-icon-column-stacked-graph:before {
  content: "\ea3f";
}
.byside-icon-bar-stacked-graph:before {
  content: "\ea40";
}
.byside-icon-chat-interno:before {
  content: "\ea3d";
}
.byside-icon-byside-small .path1:before {
  content: "\e966";
  color: rgb(52, 57, 55);
}
.byside-icon-byside-small .path2:before {
  content: "\ea28";
  margin-left: -1.1181640625em;
  color: rgb(52, 57, 55);
}
.byside-icon-byside-small .path3:before {
  content: "\ea29";
  margin-left: -1.1181640625em;
  color: rgb(52, 57, 55);
}
.byside-icon-byside-small .path4:before {
  content: "\ea2a";
  margin-left: -1.1181640625em;
  color: rgb(52, 57, 55);
}
.byside-icon-byside-small .path5:before {
  content: "\ea2b";
  margin-left: -1.1181640625em;
  color: rgb(52, 57, 55);
}
.byside-icon-byside-small .path6:before {
  content: "\ea2c";
  margin-left: -1.1181640625em;
  color: rgb(52, 57, 55);
}
.byside-icon-byside-small .path7:before {
  content: "\ea2d";
  margin-left: -1.1181640625em;
  color: rgb(219, 103, 90);
}
.byside-icon-byside-small .path8:before {
  content: "\ea2e";
  margin-left: -1.1181640625em;
  color: rgb(4, 192, 179);
}
.byside-icon-byside-small .path9:before {
  content: "\ea2f";
  margin-left: -1.1181640625em;
  color: rgb(248, 193, 88);
}
.byside-icon-byside-vertical .path1:before {
  content: "\ea30";
  color: rgb(52, 57, 55);
}
.byside-icon-byside-vertical .path2:before {
  content: "\ea31";
  margin-left: -1.08203125em;
  color: rgb(52, 57, 55);
}
.byside-icon-byside-vertical .path3:before {
  content: "\ea32";
  margin-left: -1.08203125em;
  color: rgb(52, 57, 55);
}
.byside-icon-byside-vertical .path4:before {
  content: "\ea33";
  margin-left: -1.08203125em;
  color: rgb(52, 57, 55);
}
.byside-icon-byside-vertical .path5:before {
  content: "\ea34";
  margin-left: -1.08203125em;
  color: rgb(52, 57, 55);
}
.byside-icon-byside-vertical .path6:before {
  content: "\ea35";
  margin-left: -1.08203125em;
  color: rgb(52, 57, 55);
}
.byside-icon-byside-vertical .path7:before {
  content: "\ea36";
  margin-left: -1.08203125em;
  color: rgb(219, 103, 90);
}
.byside-icon-byside-vertical .path8:before {
  content: "\ea37";
  margin-left: -1.08203125em;
  color: rgb(4, 192, 179);
}
.byside-icon-byside-vertical .path9:before {
  content: "\ea38";
  margin-left: -1.08203125em;
  color: rgb(248, 193, 88);
}
.byside-icon-byside-vertical .path10:before {
  content: "\ea39";
  margin-left: -1.08203125em;
  color: rgb(85, 85, 85);
}
.byside-icon-show:before {
  content: "\ea3a";
}
.byside-icon-dont-show:before {
  content: "\ea3b";
}
.byside-icon-lighthouse:before {
  content: "\ea27";
}
.byside-icon-exports:before {
  content: "\ea26";
}
.byside-icon-export-audio:before {
  content: "\ea21";
}
.byside-icon-export-custom:before {
  content: "\ea22";
}
.byside-icon-export-preview:before {
  content: "\ea23";
}
.byside-icon-export-report:before {
  content: "\ea24";
}
.byside-icon-export-transcript:before {
  content: "\ea25";
}
.byside-icon-cellphone:before {
  content: "\ea1f";
}
.byside-icon-inbound-cell:before {
  content: "\ea20";
}
.byside-icon-voice-inbound:before {
  content: "\ea14";
}
.byside-icon-voice-outbound:before {
  content: "\ea15";
}
.byside-icon-available:before {
  content: "\ea16";
}
.byside-icon-inbound-skill:before {
  content: "\ea17";
}
.byside-icon-not-available:before {
  content: "\ea18";
}
.byside-icon-outbound-skill:before {
  content: "\ea19";
}
.byside-icon-standby:before {
  content: "\ea1a";
}
.byside-icon-webphone-off:before {
  content: "\ea1b";
}
.byside-icon-webphone-on:before {
  content: "\ea1c";
}
.byside-icon-anonymous .path1:before {
  content: "\ea1d";
  color: rgb(255, 255, 255);
}
.byside-icon-anonymous .path2:before {
  content: "\ea1e";
  margin-left: -1.1162109375em;
  color: rgb(216, 216, 216);
}
.byside-icon-campaign-waiting-confirmation:before {
  content: "\ea13";
}
.byside-icon-campaign-click:before {
  content: "\ea08";
}
.byside-icon-campaign-closed:before {
  content: "\ea09";
}
.byside-icon-campaign-contacts:before {
  content: "\ea0a";
}
.byside-icon-campaign-failed:before {
  content: "\ea0b";
}
.byside-icon-campaign-read:before {
  content: "\ea0c";
}
.byside-icon-campaign-sent:before {
  content: "\ea0d";
}
.byside-icon-campaign-success:before {
  content: "\ea0e";
}
.byside-icon-campaign-to-send:before {
  content: "\ea0f";
}
.byside-icon-campaign-total:before {
  content: "\ea10";
}
.byside-icon-campaign-unsubscribe:before {
  content: "\ea11";
}
.byside-icon-campaign-waiting-confirmation2:before {
  content: "\ea12";
}
.byside-icon-duplicate:before {
  content: "\ea07";
}
.byside-icon-share:before {
  content: "\ea04";
}
.byside-icon-file-transfer:before {
  content: "\ea05";
}
.byside-icon-file:before {
  content: "\ea06";
}
.byside-icon-archive:before {
  content: "\e9fd";
}
.byside-icon-excel:before {
  content: "\e9fe";
}
.byside-icon-image:before {
  content: "\e9ff";
}
.byside-icon-pdf:before {
  content: "\ea00";
}
.byside-icon-powerpoint:before {
  content: "\ea01";
}
.byside-icon-text:before {
  content: "\ea02";
}
.byside-icon-word:before {
  content: "\ea03";
}
.byside-icon-upload:before {
  content: "\e9fb";
}
.byside-icon-download:before {
  content: "\e9fc";
}
.byside-icon-delete:before {
  content: "\e9f9";
}
.byside-icon-forward:before {
  content: "\e9fa";
}
.byside-icon-wrapup:before {
  content: "\e9f8";
}
.byside-icon-active-contacts:before {
  content: "\e9f5";
}
.byside-icon-external-contacts:before {
  content: "\e9f6";
}
.byside-icon-capacity:before {
  content: "\e9f7";
}
.byside-icon-campaign-closed1:before {
  content: "\e9f3";
}
.byside-icon-campaign-success1:before {
  content: "\e9f4";
}
.byside-icon-campaign-unsubscribe1:before {
  content: "\e9ed";
}
.byside-icon-campaign-click1:before {
  content: "\e9ee";
}
.byside-icon-campaign-failed1:before {
  content: "\e9ef";
}
.byside-icon-campaign-read1:before {
  content: "\e9f0";
}
.byside-icon-campaign-sent1:before {
  content: "\e9f1";
}
.byside-icon-campaign-total1:before {
  content: "\e9f2";
}
.byside-icon-company:before {
  content: "\e9ea";
}
.byside-icon-number:before {
  content: "\e9d7";
}
.byside-icon-paragraph:before {
  content: "\e9e7";
}
.byside-icon-text1:before {
  content: "\e9e8";
}
.byside-icon-map-graph:before {
  content: "\e9e6";
}
.byside-icon-mobile-notifications:before {
  content: "\e9e2";
}
.byside-icon-push-notifications:before {
  content: "\e9e3";
}
.byside-icon-website-campaign:before {
  content: "\e9e4";
}
.byside-icon-whatsapp:before {
  content: "\e9e5";
}
.byside-icon-campaigns:before {
  content: "\e9e1";
}
.byside-icon-info-2:before {
  content: "\e9df";
}
.byside-icon-newfolder:before {
  content: "\e972";
}
.byside-icon-import-csv:before {
  content: "\e9da";
}
.byside-icon-import-xls:before {
  content: "\e9db";
}
.byside-icon-import-others:before {
  content: "\e9dc";
}
.byside-icon-new_company:before {
  content: "\e9dd";
}
.byside-icon-new_contact:before {
  content: "\e9de";
}
.byside-icon-live:before {
  content: "\e9d6";
}
.byside-icon-logo:before {
  content: "\e9e9";
}
.byside-icon-notifications:before {
  content: "\e9d8";
}
.byside-icon-dashboard:before {
  content: "\e9d9";
}
.byside-icon-toggle-on .path1:before {
  content: "\e9ce";
  color: rgb(100, 201, 97);
}
.byside-icon-toggle-on .path2:before {
  content: "\e9cf";
  margin-left: -1.993759765625em;
  color: rgb(255, 255, 255);
}
.byside-icon-toogle-button:before {
  content: "\e9d0";
  color: #fff;
}
.byside-icon-toogle-off:before {
  content: "\e9d1";
  color: #e1e0e0;
}
.byside-icon-radio-on-disabled:before {
  content: "\e9d2";
  color: #e1e0e0;
}
.byside-icon-radio-off-disabled:before {
  content: "\e9d3";
  color: #e1e0e0;
}
.byside-icon-radio-off:before {
  content: "\e9d4";
  color: #64c961;
}
.byside-icon-radio-on:before {
  content: "\e9d5";
  color: #64c961;
}
.byside-icon-info:before {
  content: "\e9cb";
}
.byside-icon-alert:before {
  content: "\e9e0";
}
.byside-icon-arrow-close:before {
  content: "\e9cc";
}
.byside-icon-arrow-open:before {
  content: "\e9cd";
}
.byside-icon-des:before {
  content: "\e9c9";
}
.byside-icon-asc:before {
  content: "\e9ca";
}
.byside-icon-preview-msg:before {
  content: "\e909";
}
.byside-icon-link:before {
  content: "\e90a";
}
.byside-icon-emoji:before {
  content: "\e90d";
}
.byside-icon-chatbot:before {
  content: "\e900";
}
.byside-icon-filter-sort:before {
  content: "\e989";
}
.byside-icon-app-call-total-operators:before {
  content: "\e9c2";
}
.byside-icon-app-call-falando-out:before {
  content: "\e9bb";
}
.byside-icon-bold:before {
  content: "\e9bc";
}
.byside-icon-expression-plus:before {
  content: "\e9bd";
}
.byside-icon-italic:before {
  content: "\e9be";
}
.byside-icon-percentagem:before {
  content: "\e9bf";
}
.byside-icon-racio:before {
  content: "\e9c0";
}
.byside-icon-underline:before {
  content: "\e9c1";
}
.byside-icon-app-call-all:before {
  content: "\e9b1";
}
.byside-icon-app-call-loginin:before {
  content: "\e9b2";
}
.byside-icon-app-call-on-hold:before {
  content: "\e9b3";
}
.byside-icon-app-call-pause:before {
  content: "\e9b4";
}
.byside-icon-app-call-stop:before {
  content: "\e9b5";
}
.byside-icon-app-call-talking:before {
  content: "\e9b6";
}
.byside-icon-app-call-waiting:before {
  content: "\e9b7";
}
.byside-icon-font:before {
  content: "\e9b8";
}
.byside-icon-pencil:before {
  content: "\e9b9";
}
.byside-icon-visible:before {
  content: "\e9ba";
}
.byside-icon-broadcast:before {
  content: "\e9ad";
}
.byside-icon-ended-broadcasts:before {
  content: "\e9ae";
}
.byside-icon-New-broadcast:before {
  content: "\e9af";
}
.byside-icon-ongoing-broadcast:before {
  content: "\e9b0";
}
.byside-icon-broadcast-camera:before {
  content: "\e9a4";
}
.byside-icon-broadcast-mute:before {
  content: "\e9a5";
}
.byside-icon-broadcast-error:before {
  content: "\e9ec";
}
.byside-icon-broadcast-ok:before {
  content: "\e9eb";
}
.byside-icon-broadcast-pause:before {
  content: "\e9a6";
}
.byside-icon-broadcast-play:before {
  content: "\e9a7";
}
.byside-icon-broadcast-record:before {
  content: "\e9a8";
}
.byside-icon-broadcast-smaller:before {
  content: "\e9a9";
}
.byside-icon-broadcast-stop:before {
  content: "\e9aa";
}
.byside-icon-broadcast-unmute:before {
  content: "\e9ab";
}
.byside-icon-broadcast-bigger:before {
  content: "\e9ac";
}
.byside-icon-broadcast-messages:before {
  content: "\e99f";
}
.byside-icon-broadcast-online:before {
  content: "\e9a0";
}
.byside-icon-broadcast-viewers:before {
  content: "\e9a1";
}
.byside-icon-broadcast-likes:before {
  content: "\e9a2";
}
.byside-icon-new-messages:before {
  content: "\e9a3";
}
.byside-icon-unmute1:before {
  content: "\e99d";
}
.byside-icon-mute1:before {
  content: "\e99e";
}
.byside-icon-maximize-fullscreen:before {
  content: "\e99b";
}
.byside-icon-minimize-fullscreen:before {
  content: "\e99c";
}
.byside-icon-screen-sharing-pause:before {
  content: "\e998";
}
.byside-icon-screen-sharing-stop:before {
  content: "\e999";
}
.byside-icon-screen-sharing:before {
  content: "\e99a";
}
.byside-icon-call-active:before {
  content: "\e991";
}
.byside-icon-call-free:before {
  content: "\e992";
}
.byside-icon-call-login-in:before {
  content: "\e993";
}
.byside-icon-call-on-hold:before {
  content: "\e994";
}
.byside-icon-call-paused:before {
  content: "\e995";
}
.byside-icon-call-scheduled:before {
  content: "\e996";
}
.byside-icon-call-speaking:before {
  content: "\e997";
}
.byside-icon-visitors:before {
  content: "\e98f";
}
.byside-icon-pages:before {
  content: "\e990";
}
.byside-icon-available-operators:before {
  content: "\e904";
}
.byside-icon-schedule-calls:before {
  content: "\e905";
}
.byside-icon-waiting-in-queue:before {
  content: "\e906";
}
.byside-icon-waiting-calls:before {
  content: "\e907";
}
.byside-icon-active-chats:before {
  content: "\e901";
}
.byside-icon-active-calls:before {
  content: "\e902";
}
.byside-icon-play-stream:before {
  content: "\e97b";
}
.byside-icon-record-stream:before {
  content: "\e987";
}
.byside-icon-unmute:before {
  content: "\e988";
}
.byside-icon-pause-call:before {
  content: "\e97c";
}
.byside-icon-mute:before {
  content: "\e97d";
}
.byside-icon-live-queue:before {
  content: "\e97e";
}
.byside-icon-live-waiting:before {
  content: "\e97f";
}
.byside-icon-live-active:before {
  content: "\e980";
}
.byside-icon-live-schedule:before {
  content: "\e981";
}
.byside-icon-folder:before {
  content: "\e982";
}
.byside-icon-dashboard-settings:before {
  content: "\ea53";
}
.byside-icon-videoconf:before {
  content: "\e984";
}
.byside-icon-hangup:before {
  content: "\e985";
}
.byside-icon-billing:before {
  content: "\e986";
}
.byside-icon-grid:before {
  content: "\e976";
}
.byside-icon-list:before {
  content: "\e977";
}
.byside-icon-ascending:before {
  content: "\e978";
}
.byside-icon-descending:before {
  content: "\e979";
}
.byside-icon-filter:before {
  content: "\e97a";
}
.byside-icon-consola:before {
  content: "\e90c";
}
.byside-icon-apps:before {
  content: "\e903";
}
.byside-icon-external .path1:before {
  content: "\e908";
  color: rgb(255, 255, 255);
}
.byside-icon-external .path2:before {
  content: "\e98a";
  margin-left: -1.0439453125em;
  color: rgb(179, 179, 179);
}
.byside-icon-external .path3:before {
  content: "\e98b";
  margin-left: -1.0439453125em;
  color: rgb(179, 179, 179);
}
.byside-icon-external .path4:before {
  content: "\e98c";
  margin-left: -1.0439453125em;
  color: rgb(179, 179, 179);
}
.byside-icon-external .path5:before {
  content: "\e98d";
  margin-left: -1.0439453125em;
  color: rgb(179, 179, 179);
}
.byside-icon-external .path6:before {
  content: "\e98e";
  margin-left: -1.0439453125em;
  color: rgb(179, 179, 179);
}
.byside-icon-external .path7:before {
  content: "\e9c4";
  margin-left: -1.0439453125em;
  color: rgb(179, 179, 179);
}
.byside-icon-external .path8:before {
  content: "\e9c5";
  margin-left: -1.0439453125em;
  color: rgb(179, 179, 179);
}
.byside-icon-external .path9:before {
  content: "\e9c6";
  margin-left: -1.0439453125em;
  color: rgb(179, 179, 179);
}
.byside-icon-external .path10:before {
  content: "\e9c7";
  margin-left: -1.0439453125em;
  color: rgb(179, 179, 179);
}
.byside-icon-external .path11:before {
  content: "\e9c8";
  margin-left: -1.0439453125em;
  color: rgb(179, 179, 179);
}
.byside-icon-wand:before {
  content: "\e90b";
}
.byside-icon-pause-icon .path1:before {
  content: "\e942";
  color: rgb(255, 255, 255);
}
.byside-icon-pause-icon .path2:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(179, 179, 179);
}
.byside-icon-pause-icon .path3:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.byside-icon-pause-icon .path4:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.byside-icon-chat-icon .path1:before {
  content: "\e946";
  color: rgb(255, 255, 255);
}
.byside-icon-chat-icon .path2:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.byside-icon-chat-icon .path3:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(179, 179, 179);
}
.byside-icon-outbound-icon .path1:before {
  content: "\e949";
  color: rgb(179, 179, 179);
}
.byside-icon-outbound-icon .path2:before {
  content: "\e94a";
  margin-left: -0.9892578125em;
  color: rgb(29, 29, 27);
}
.byside-icon-outbound-icon .path3:before {
  content: "\e94b";
  margin-left: -0.9892578125em;
  color: rgb(255, 255, 255);
}
.byside-icon-outbound-icon .path4:before {
  content: "\e94c";
  margin-left: -0.9892578125em;
  color: rgb(179, 179, 179);
}
.byside-icon-play-icon .path1:before {
  content: "\e94d";
  color: rgb(255, 255, 255);
}
.byside-icon-play-icon .path2:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(100, 201, 97);
}
.byside-icon-play-icon .path3:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.byside-icon-voice-icon .path1:before {
  content: "\e950";
  color: rgb(29, 29, 27);
}
.byside-icon-voice-icon .path2:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.byside-icon-voice-icon .path3:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(179, 179, 179);
}
.byside-icon-forms-icon .path1:before {
  content: "\e953";
  color: rgb(255, 255, 255);
}
.byside-icon-forms-icon .path2:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(179, 179, 179);
}
.byside-icon-forms-icon .path3:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(179, 179, 179);
}
.byside-icon-forms-icon .path4:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.byside-icon-forms-icon .path5:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.byside-icon-forms-icon .path6:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.byside-icon-forms-icon .path7:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.byside-icon-forms-icon .path8:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.byside-icon-forms-icon .path9:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.byside-icon-forms-icon .path10:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.byside-icon-forms-icon .path11:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.byside-icon-inbound-icon .path1:before {
  content: "\e95e";
  color: rgb(179, 179, 179);
}
.byside-icon-inbound-icon .path2:before {
  content: "\e95f";
  margin-left: -0.9892578125em;
  color: rgb(29, 29, 27);
}
.byside-icon-inbound-icon .path3:before {
  content: "\e960";
  margin-left: -0.9892578125em;
  color: rgb(255, 255, 255);
}
.byside-icon-inbound-icon .path4:before {
  content: "\e961";
  margin-left: -0.9892578125em;
  color: rgb(179, 179, 179);
}
.byside-icon-performance-active:before {
  content: "\e962";
}
.byside-icon-performance-waiting:before {
  content: "\e90e";
}
.byside-icon-performance-free:before {
  content: "\e90f";
}
.byside-icon-performance-hold:before {
  content: "\e910";
}
.byside-icon-performance-pause:before {
  content: "\e911";
}
.byside-icon-performance-speaking:before {
  content: "\e963";
}
.byside-icon-operator:before {
  content: "\e912";
}
.byside-icon-check-partial:before {
  content: "\e913";
  color: #64c961;
}
.byside-icon-check-off-disabled:before {
  content: "\e914";
  color: #d8d8d8;
}
.byside-icon-check-on-disabled:before {
  content: "\e915";
  color: #d8d8d8;
}
.byside-icon-check-on:before {
  content: "\e916";
  color: #64c961;
}
.byside-icon-check-partial-disabled:before {
  content: "\e917";
  color: #d8d8d8;
}
.byside-icon-phone:before {
  content: "\e918";
}
.byside-icon-chat-inbound:before {
  content: "\e919";
}
.byside-icon-chat-outbound:before {
  content: "\e91a";
}
.byside-icon-check-off:before {
  content: "\e91b";
  color: #64c961;
}
.byside-icon-pause .path1:before {
  content: "\e91c";
}
.byside-icon-pause .path2:before {
  content: "\e973";
  margin-left: -0.93359375em;
}
.byside-icon-Play .path1:before {
  content: "\e91d";
  color: rgb(100, 201, 97);
}
.byside-icon-Play .path2:before {
  content: "\e975";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.byside-icon-chat:before {
  content: "\e91e";
}
.byside-icon-form-on:before {
  content: "\e91f";
}
.byside-icon-phone-outbound:before {
  content: "\e920";
}
.byside-icon-phone-inbound:before {
  content: "\e921";
}
.byside-icon-play-large:before {
  content: "\e922";
}
.byside-icon-show-less:before {
  content: "\e923";
}
.byside-icon-duration:before {
  content: "\e924";
}
.byside-icon-no-sell:before {
  content: "\e925";
}
.byside-icon-sell:before {
  content: "\e926";
}
.byside-icon-time:before {
  content: "\e927";
}
.byside-icon-trick:before {
  content: "\e928";
}
.byside-icon-bad-call:before {
  content: "\e929";
}
.byside-icon-landing-page:before {
  content: "\e92a";
}
.byside-icon-refuse:before {
  content: "\e92b";
}
.byside-icon-schedule:before {
  content: "\e92c";
}
.byside-icon-telefonia:before {
  content: "\e92d";
}
.byside-icon-voice:before {
  content: "\e92e";
}
.byside-icon-web2lead:before {
  content: "\e92f";
}
.byside-icon-website:before {
  content: "\e930";
}
.byside-icon-click2call:before {
  content: "\e931";
}
.byside-icon-email:before {
  content: "\e932";
}
.byside-icon-messenger:before {
  content: "\e933";
}
.byside-icon-sms:before {
  content: "\e934";
}
.byside-icon-dropdown:before {
  content: "\e935";
}
.byside-icon-team-icon:before {
  content: "\e936";
}
.byside-icon-total-calls:before {
  content: "\e937";
}
.byside-icon-total-classified:before {
  content: "\e938";
}
.byside-icon-total-sells:before {
  content: "\e939";
}
.byside-icon-column-graph:before {
  content: "\e93a";
}
.byside-icon-bar-graph:before {
  content: "\ea3e";
}
.byside-icon-pie-graph:before {
  content: "\e93b";
}
.byside-icon-line-graph:before {
  content: "\e93c";
}
.byside-icon-trash:before {
  content: "\e93d";
}
.byside-icon-user:before {
  content: "\e93e";
}
.byside-icon-arrow-large:before {
  content: "\e93f";
}
.byside-icon-form:before {
  content: "\e940";
}
.byside-icon-chat-settings:before {
  content: "\e941";
}
.byside-icon-team:before {
  content: "\e964";
}
.byside-icon-calendar:before {
  content: "\e965";
}
.byside-icon-close:before {
  content: "\e967";
}
.byside-icon-maximize:before {
  content: "\e968";
}
.byside-icon-performance:before {
  content: "\e969";
}
.byside-icon-arrow-small:before {
  content: "\e96a";
}
.byside-icon-minimize:before {
  content: "\e96b";
}
.byside-icon-refresh:before {
  content: "\e96c";
}
.byside-icon-user-settings:before {
  content: "\e96d";
}
.byside-icon-settings-small:before {
  content: "\e9c3";
}
.byside-icon-filter2:before {
  content: "\e96e";
}
.byside-icon-leads:before {
  content: "\e96f";
}
.byside-icon-marketing:before {
  content: "\e970";
}
.byside-icon-search:before {
  content: "\e971";
}
.byside-icon-byside:before {
  content: "\ea3c";
}
